.ofert {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  margin: 0 auto 20px auto;
  width: 80%;
  max-width: 1600px;
  min-height: 210px;
  display: none;
  justify-content: space-between;
  align-items: center;

  .ofert1 {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    justify-content: left;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 30px;

    .ofertMain {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      margin: 0 auto;
      width: 90%;
      height: 80%;

      h3 {
        margin-top: 0;
        color: #faaf47;
        font-weight: 600;
        font-size: 30px;
      }

      .ofertAll {
        display: flex;
        justify-content: space-between;
        padding: 10px 0 10px 0;
        margin: 0 auto;
        width: 100%;
        height: 6.3%;

        .ofertTxt {
          margin: 0;
          width: 75%;
          height: 100%;

          h3 {
            margin-top: 10px;
            color: #faaf47;
            font-weight: 600;
            font-size: 25px;
          }

          p {
            color: #fff;
            font-weight: 500;
            font-size: 20px;
            text-align: left;

            a {
              text-decoration: none;
              color: #faaf47;
              transition: .5s all;

              &:hover {
                text-shadow: 0 0 3px #faaf47;
              }
            }

            ul{
              margin: 10px 0;
              padding-left: 20px;
            }
          }
        }

        .ofertPrice {
          padding-top: 15px;
          width: 20%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            margin: 0;
            text-align: center;
            width: 100%;
            height: 100%;
            font-size: 25px;
            color: #faaf47;
            white-space: nowrap;
          }
        }

        .ofertTxtToContact {
          margin: 0;
          width: 100%;
          height: 100%;

          h3 {
            margin-top: 10px;
            color: #faaf47;
            font-weight: 600;
            font-size: 25px;
          }

          p {
            color: #fff;
            font-weight: 500;
            font-size: 20px;
            text-align: left;
          }

          a {
            text-decoration: none;
            color: #faaf47;
            transition: .5s all;

            &:hover {
              text-shadow: 0 0 3px #faaf47;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .ofert {
    .ofert1 {
      .ofertMain {

        .ofertAll {

          .ofertPrice {
            width: 25%;

            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .ofert {
    .ofert1 {
      .ofertMain {

        .ofertAll {

          .ofertTxt {
            h3 {
              font-size: 22px;
            }

            p {
              font-size: 18px;
            }
          }

          .ofertPrice {
            width: 30%;
            padding-top: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .ofert {
    width: 90%;
    .ofert1 {
      .ofertMain {

        .ofertAll {

          .ofertPrice {
            p {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .ofert {
    .ofert1 {
      .ofertMain {

        .ofertAll {

          .ofertPrice {
            padding-top: 15px;
            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}