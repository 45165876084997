.header {
  margin: 0 auto 20px auto;
  padding: 10px 0 0 0;
  min-width: 99vw;
  box-sizing: border-box;
  font-family: 'Rajdhani', sans-serif;
}

.header-container {
  max-width: 1600px;
  width: 80%;
  height: 180px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  .logo {
    width: 240px;
    height: 180px;
    min-width: 240px;
    min-height: 180px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      box-shadow: 0 0 8px #FAAF47;
      cursor: pointer;
    }
  }

  .navigation {
    width: 100%;
    height: 40%;
    padding: 0 40px 0 40px;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 0 30px 30px 0;

    .nav1 {
      padding-inline-start: 0;
      position: relative;
      margin: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      font-size: 25px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        text-transform: uppercase;
        font-weight: 400;
        transition: 2s all;


        a {
          text-decoration: none;
          color: rgb(255, 255, 255);
          transition: 0.5s all;
          position: relative;

        }

        a:before {
          content: '';
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: 0;
          background: #FAAF47;
          border-radius: 5%;
          transition: 0.5s all;
        }

        a:hover {
          color: #FAAF47;
          text-shadow: 0 0 10px #FAAF47;
        }

        a:hover:before {
          left: 0;
          right: 0;
          box-shadow: 0 0 8px #FAAF47;
          height: 3px;
        }

        a.active {
          color: #FAAF47;
          text-shadow: 0 0 10px #FAAF47;
        }

        a.active:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background: #FAAF47;
          border-radius: 5%;
          box-shadow: 0 0 8px #FAAF47;
          height: 3px;
        }
      }
    }
  }
}


@media (max-width: 1200px) {
  .header-container {
    .navigation {
      .nav1 {
        font-size: 25px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .header-container {
    .navigation {
      .nav1 {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 880px) {
  .header-container {
    .navigation {
      padding-left: 20px;
      padding-right: 20px;

      .nav1 {
        font-size: 20px;
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: 810px) {
  .header-container {
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 30px;

    .navigation {
      padding-left: 40px;
      padding-right: 40px;
      background: none;

      .nav1 {
        font-size: 25px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        li {
          padding: 5px 0;
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .header-container {
    .navigation {
      padding-left: 10px;
      padding-right: 10px;

      .nav1 {

        li {
          padding: 7px 0;
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .header-container {
    .navigation {
      .nav1 {
        font-size: 20px;

        li {
          padding: 10px 0;
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .header-container {
    width: 90%;
    flex-direction: column;
    min-height: 350px;

    .navigation {
      .nav1 {
        padding-top: 15px;
        font-size: 20px;

        li {
          padding: 5px 0;
        }
      }
    }
  }
}
