::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #666;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 170, 81, 0.6);
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(122, 122, 122, 0.5);
}
body {
    overflow-x: hidden;
    margin: 0 auto;
}
@media (max-width: 800px){
    ::-webkit-scrollbar {
        width: 7px;
    }
}

