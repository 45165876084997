.footer {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600;
  margin: 0 auto;
  padding-top: 10px;
  width: 80%;
  max-width: 1600px;
  height: 210px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 30px;
}

.media {
  height: 100%;
  width: 30%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  padding-left: 50px;

  h3 {
    margin: 10px;
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .icons {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-weight: 400;
      text-decoration: none;
      font-size: 60px;

      color: #fff;
      transition: 0.5s all;
    }

    a:nth-of-type(1):hover {
      color: #4572bb;
      text-shadow: 0 0 3px #4572bb;
    }

    a:nth-of-type(2):hover {
      color: #10ABF6;
      text-shadow: 0 0 3px #10ABF6;
    }

    a:nth-of-type(3):hover {
      color: #9b55ff;
      text-shadow: 0 0 3px #9b55ff;
    }
  }
}

.contactFoot {
  padding-right: 30px;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 25px;
    text-transform: uppercase;
    margin: 10px 0 10px 25px;
    font-weight: 400;

  }

  p {
    font-size: 20px;
    font-weight: 500;
    margin: 5px;

    &:nth-of-type(2) {
      padding-left: 23px;
    }

    a {
      text-decoration: none;
      color: #faaf47;
      transition: .5s all;

      &:hover {
        text-shadow: 0 0 3px #FAAF47;
      }
    }

    i {
      color: #FAAF47;
      padding-right: 10px;
    }
  }

}

@media (max-width: 1100px) {
  .media {
    width: 25%;
  }
}

@media (max-width: 900px) {
  .media {
    width: 30%;

    .icons{
      a:nth-of-type(1) {
        color: #4572bb;
        text-shadow: 0 0 2px #4572bb;
      }

      a:nth-of-type(2) {
        color: #10ABF6;
        text-shadow: 0 0 2px #10ABF6;
      }

      a:nth-of-type(3) {
        color: #9b55ff;
        text-shadow: 0 0 2px #9b55ff;
      }

      a:nth-of-type(1):hover {
        color: #4572bb;
        text-shadow: 0 0 2px #4572bb;
      }

      a:nth-of-type(2):hover {
        color: #10ABF6;
        text-shadow: 0 0 2px #10ABF6;
      }

      a:nth-of-type(3):hover {
        color: #9b55ff;
        text-shadow: 0 0 2px #9b55ff;
      }
    }
  }
}


@media (max-width: 850px) {
  .footer {
    height: 180px;
  }
  .contactFoot {
    p {
      font-size: 15px;
    }
  }
  .media {
    width: 30%;

    .icons {
      a {
        font-size: 50px;
      }
    }
  }
}

@media (max-width: 700px) {
  .media {
    width: 30%;

    .icons {
    }
  }
}

@media (max-width: 630px) {
  .footer {
    height: 150px;
    padding-top: 0;
  }
  .contactFoot {
    padding-right: 10px;

    h3 {
      font-size: 15px;
      font-weight: 500;
    }

    p {
      font-size: 12px;
    }
  }
  .media {
    width: 35%;
    padding-left: 20px;

    h3 {
      font-weight: 500;
      font-size: 15px;
    }

    .icons {
      a {
        font-size: 40px;
      }
    }
  }
}
@media (max-width: 470px) {
  .footer {
    width: 90%;
  }
}

@media (max-width: 450px) {
  .footer {
    flex-direction: column;
    height: 300px;
  }
  .media {
    width: 50%;
    padding-left: 0;
  }
  .contactFoot {
    align-items: center;

    h3 {
      margin: 10px 0 10px 15px;
    }

    p {
      font-size: 15px;
    }
  }
}

@media (max-width: 350px) {
  .footer {
    flex-direction: column;
    height: 300px;
  }
  .media {
    width: 70%;
  }
  .contactFoot {
    p {
      font-size: 12px;
    }
  }
}