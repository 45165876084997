.copyright {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 30px;
  margin: 20px auto 0 auto;
  width: 80%;
  max-width: 1600px;

  p {
    font-size: 16px;
    padding: 2px 0 2px 0;
    text-align: center;
    color: #FAAF47;
    margin: 10px 0 0 0;
    span{
      font-size: 20px;
    }
    a{
      text-decoration: none;
      color: #faaf47;
    }
  }
}
@media (max-width: 470px) {
  .copyright{
    width: 90%;
    p {
      font-size: 12px;
      padding: 2px 0 2px 0;
      text-align: center;
      color: #FAAF47;
      margin: 10px 0 0 0;
      span{
        font-size: 14px;
      }
      a{
        text-decoration: none;
        color: #faaf47;
      }
    }
  }
}
