.contact {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  margin: 0 auto 20px auto;
  padding-top: 10px;
  width: 80%;
  max-width: 1600px;
  min-height: 520px;
  display: none;
  flex-direction: column;
  justify-content: left;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 30px;

  .contactMain {
    margin: 0 auto;
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .contactTxt {
      margin: 0 auto;
      width: 100%;
      height: 100%;

      h3{
        margin-top: 10px;
        color: #faaf47;
        font-weight: 600;
        font-size: 30px;
      }

      .contactMap{
        min-height: 400px;
        width: 100%;
        position: relative;
      }


      .contactUs {
        padding: 50px 0 0 0;
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;

        p {
          font-size: 25px;
          font-weight: 500;
          margin: 5px;

          &:nth-of-type(2) {
            padding-left: 30px;
          }

          a {
            text-decoration: none;
            color: #faaf47;
            transition: .5s all;

            &:hover {
              text-shadow: 0 0 3px #FAAF47;
            }
          }

          i {
            color: #FAAF47;
            padding-right: 10px;
          }
        }

      }
    }
  }
}

@media (max-width: 470px) {
  .contact {
    width: 90%;
  }
}

@media (max-width: 373px) {
  .contact {
    min-height: 550px;
  }
}