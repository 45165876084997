.app {
  margin: 0 auto;
  background-image: url('./images/carbbg.jpg');
  background-size: cover;
  min-height: 100vh;

  .page {
    display: block;
    position: relative;

    .cookiesMiniInfo {
      font-family: 'Rajdhani', sans-serif;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: fixed;
      bottom: 0;
      border-top: 2px solid #faaf47;
      padding-bottom: 10px;
      z-index: 2;

      p {
        color: #fff;
        width: 85%;
        text-align: center;

        .cookiesMore {
          text-decoration: none;
          color: #faaf47;
          transition: .5s all;

          &:hover {
            text-shadow: 0 0 3px #faaf47;
          }
        }
      }

      .cookiesMiniBtn {
        width: 50px;
        height: 25px;
        border: none;
        border-radius: 7px;
        background-color: #faaf47;
        color: #111;
        transition: .5s all;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 3px #faaf47;
        }
      }
    }

    .cookiesMaxiInfo {
      margin: 0;
      font-family: 'Rajdhani', sans-serif;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      display: none;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.9);

      p {
        margin: 0;
        padding-top: 50px;
        width: 70%;
        height: 90%;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
      }

      .cookiesCross {
        height: 90%;
        margin: 0;
        padding-top: 20px;
        font-size: 25px;
        color: #faaf47;
        cursor: pointer;
      }
    }

    .scrollUp {
      display: none;
      width: 50px;
      height: 50px;
      background-color: #FAAF47;
      border-radius: 30%;
      text-align: center;
      position: fixed;
      right: 10px;
      bottom: 10px;
      opacity: .5;
      cursor: pointer;
      z-index: 3;

      i {
        color: #fff;
        font-size: 30px;
        margin-top: 10px;

        &:hover {
          animation: scroll .6s linear infinite;

        }
      }

      @keyframes scroll {
        0% {
          margin-top: 10px;
        }
        50% {
          margin-top: 0;
        }
        100% {
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .app {
    .page {
      .cookiesMaxiInfo {
        p {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .app {
    .page {
      .scrollUp {
        display: none;
        width: 30px;
        height: 30px;
        background-color: #FAAF47;
        border-radius: 20%;
        text-align: center;
        position: fixed;
        right: 5px;
        bottom: 5px;
        opacity: .5;
        cursor: pointer;
        z-index: 2;

        i {
          color: #fff;
          font-size: 20px;
          margin-top: 5px;
        }
      }
    }
  }
  .footer {
    flex-direction: column;
    height: 300px;
  }
  .media {
    width: 50%;
  }
}
