.main-slider {
  margin: 20px auto;
  padding: 0;
  min-height: 20vh;
  width: 70%;
  max-width: 1440px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 1s all;

  .slider-images {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    animation-name: trans;
    animation-duration: 6s;

    .slide1 {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 30px;

        @keyframes trans {
          0% {
            opacity: 0
          }
          10% {
            opacity: 1
          }
          90% {
            opacity: 1
          }
          100% {
            opacity: 0
          }
        }
      }
    }

    .slide2 {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 30px;

        @keyframes trans {
          0% {
            opacity: 0
          }
          10% {
            opacity: 1
          }
          90% {
            opacity: 1
          }
          100% {
            opacity: 0
          }
        }
      }
    }

    .slide3 {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 30px;

        @keyframes trans {
          0% {
            opacity: 0
          }
          10% {
            opacity: 1
          }
          90% {
            opacity: 1
          }
          100% {
            opacity: 0
          }
        }
      }
    }

    .slide4 {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 30px;

        @keyframes trans {
          0% {
            opacity: 0
          }
          10% {
            opacity: 1
          }
          90% {
            opacity: 1
          }
          100% {
            opacity: 0
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .main-slider{
    width: 80%;
  }
}

@media (max-width: 470px) {
  .main-slider{
    width: 90%;
  }
}
